import React from "react";
import { Carousel } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import GreenButton from "../Button/GreenButton";
import OrangeYellowButton from "../Button/OrangeYellowButton";
import "./Hero.scss";
import HeroTab from "./heroTab";
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";

const Hero = (props) => {
  const { i18n } = useI18next();
  const { t } = useTranslation();

  return (
    <>
      <div className={`hero-wrapper my__carousel_main`}>
        <Carousel className="carouselWrapper" interval={3000}>
          <Carousel.Item className="carouselItem">
            {i18n.language !== "th" ? (
              <StaticImage
                src="../../img/hero1.png"
                alt="hero1"
                className="w-100 d-block hero-img"
                placeholder="blurred"
              />
            ) : (
              <StaticImage
                src="../../img/herothai1.png"
                alt="FIRST PRIDE ที่สุดของแพลนต์เบส อร่อยถูกใจจนลืมไปเลยว่าทำมาจากพืช"
                className="w-100 d-block hero-img"
                placeholder="blurred"
              />
            )}
            <Carousel.Caption className="heroContent">
              <h2>{t("hero.title1")}</h2>
              {/**only 1 h1 tag per document for seo */}
              <h1 className="subtitleH1">
                <span className="boldHero">{t("hero.boldsubtitle1")}</span>{" "}
                {t("hero.subtitle1")}
              </h1>
              <div className="heroButton">
                <GreenButton
                  msg={t("hero.button")}
                  color="green"
                  to="/aboutus"
                  yellow="#FFE055"
                />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carouselItem">
            <StaticImage
              src="../../img/hero2.png"
              alt="FIRST PRIDE ประสบการณ์ความอร่อยของอาหารแพลนต์เบส ด้วยหลากหลายเมนูที่ทำให้อร่อยได้อย่างง่าย ๆ"
              className="w-100 d-block hero-img"
              placeholder="blurred"
            />
            <Carousel.Caption className="heroContent">
              <h2>{t("hero.title2")}</h2>
              <h3>
                <span className="boldHero">{t("hero.boldsubtitle2")}</span>{" "}
                {t("hero.subtitle2")}
              </h3>
              <div className="heroButton">
                <GreenButton
                  msg={t("hero.button")}
                  color="green"
                  to="/products#snacks"
                  yellow="#FFE055"
                />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carouselItem">
            {i18n.language !== "th" ? (
              <StaticImage
                src="../../img/hero3.jpg"
                alt="FIRST PRIDE Thai Taste Series"
                className="w-100 d-block hero-img"
                placeholder="blurred"
              />
            ) : (
              <StaticImage
                src="../../img/hero3thai.jpg"
                alt="FIRST PRIDE Thai Taste Series แพลนต์เบสอร่อยแซ่บ สไตล์ไทยแท้"
                className="w-100 d-block hero-img"
                placeholder="blurred"
              />
            )}
            <Carousel.Caption className="heroContent">
              <div className="heroButton">
                <OrangeYellowButton
                  msg={t("hero.button")}
                  to="/products#thai"
                />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carouselItem">
            <StaticImage
              src="../../img/hero42.jpg"
              alt="FIRST PRIDE J Series อร่อยแท้ ไม่แพ้เนื้อ"
              className="w-100 d-block hero-img"
              placeholder="blurred"
            />
            <Carousel.Caption className="heroContent">
              <div className="heroButton">
                <OrangeYellowButton
                  msg={t("hero.button")}
                  color="green"
                  to="/products#snacks"
                  yellow="#FFE055"
                />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <div className="cornerGraphic">
          <StaticImage
            src="../../img/banner_pattern.png"
            alt="Patter"
            className="orangeGraphic"
          />
        </div>
      </div>
      <HeroTab props={props} />
      <div className={`heroWrapperMobile `}>
        <Carousel className="carouselWrapperMobile" interval={3000}>
          <Carousel.Item className="carouselItem">
            {i18n.language !== "th" ? (
              <StaticImage
                src="../../img/hero1mob.png"
                alt="hero1"
                className="w-100 d-block hero-img"
                placeholder="blurred"
              />
            ) : (
              <StaticImage
                src="../../img/herothai1mob.png"
                alt="FIRST PRIDE ที่สุดของแพลนต์เบส อร่อยถูกใจจนลืมไปเลยว่าทำมาจากพืช"
                className="w-100 d-block hero-img"
                placeholder="blurred"
              />
            )}
            <Carousel.Caption className="heroContent">
              <h2>{t("hero.title1")}</h2>
              <h3>
                <span className="boldHero">{t("hero.boldsubtitle1")}</span>{" "}
                {t("hero.subtitle1")}
              </h3>
              <div className="heroButton">
                <GreenButton
                  msg={t("hero.button")}
                  color="green"
                  to="/aboutus"
                  yellow="#FFE055"
                />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carouselItem">
            <StaticImage
              src="../../img/hero2mobile.png"
              alt="FIRST PRIDE ประสบการณ์ความอร่อยของอาหารแพลนต์เบส ด้วยหลากหลายเมนูที่ทำให้อร่อยได้อย่างง่าย ๆ"
              className="w-100 d-block hero-img"
              placeholder="blurred"
            />
            <Carousel.Caption className="heroContent">
              <h2>{t("hero.title2")}</h2>
              <h3>
                <span className="boldHero">{t("hero.boldsubtitle2")}</span>{" "}
                {t("hero.subtitle2")}
              </h3>
              <div className="heroButton">
                <GreenButton
                  msg={t("hero.button")}
                  color="green"
                  to="/products#snacks"
                  yellow="#FFE055"
                />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carouselItem">
            {i18n.language !== "th" ? (
              <StaticImage
                src="../../img/hero3mobile.jpg"
                alt="FIRST PRIDE Thai Taste Series"
                className="w-100 d-block hero-img"
                placeholder="blurred"
              />
            ) : (
              <StaticImage
                src="../../img/hero3mobilethai.jpg"
                alt="FIRST PRIDE Thai Taste Series แพลนต์เบสอร่อยแซ่บ สไตล์ไทยแท้"
                className="w-100 d-block hero-img"
                placeholder="blurred"
              />
            )}
            <Carousel.Caption className="heroContent">
              <div className="heroButton">
                <OrangeYellowButton
                  msg={t("hero.button")}
                  color="green"
                  to="/products#thai"
                  yellow="#FFE055"
                />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carouselItem">
            <StaticImage
              src="../../img/hero42mobile.jpg"
              alt="FIRST PRIDE J Series อร่อยแท้ ไม่แพ้เนื้อ"
              className="w-100 d-block hero-img"
              placeholder="blurred"
            />
            <Carousel.Caption className="heroContent">
              <div className="heroButton">
                <OrangeYellowButton
                  msg={t("hero.button")}
                  color="green"
                  to="/products#snacks"
                  yellow="#FFE055"
                />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <div className="cornerGraphic">
          <StaticImage
            src="../../img/banner_pattern.png"
            alt="Patter"
            className="orangeGraphic"
          />
        </div>
      </div>
    </>
  );
};
export default (Hero);

