import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./faqs.scss";
import GreenButton from "../Button/GreenButton";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';

const Faqs = () => {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "faqsbg.webp" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const { t } = useTranslation();
  // Set ImageData
  const imageData = data.background.childImageSharp.fluid;
    
    return (
        <BackgroundImage
          Tag="div"
          className={"faqsSection"}
          fluid={imageData}
          backgroundColor=""
        >
            <div className="faqsWrapper">
                <h2>{t("faq.title1")}</h2>
                <h5 className="p-regular">{t("faq.subtitle1")}</h5>
                <div className="btnBox">
                    <GreenButton icon="forward" msg={t("faq.button")} to="/faq" yellow={true} />
                </div>
            </div>
            <div className="faqsImg">
                <StaticImage src="../../img/faqs1.webp" placeholder="blurred" />
            </div>
        </BackgroundImage>
    );
};

export default (Faqs);
