import React from "react";
import AboutUsHome from "../components/AboutUsHome/AboutUsHome";
import AboutUsHomeMobile from "../components/AboutUsHome/AboutUsHomeMobile";
import Faqs from "../components/Faq/faqs";
import Hero from "../components/Hero/Hero";
import Layout from "../components/Layout";
import ProductsHome from "../components/ProductsHome/ProductsHome";
import WhereToBuyUs from "../components/WhereToBuyUs/wheretobuyus";
import Helmet from "react-helmet";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";

const Home = () => {
    const {t} = useTranslation();
    
    return (
        <Layout title={t("home.metatitle")} description={t("home.metadescription")}>
            <Helmet> 
                <link rel="alternate" href="https://www.firstpridethailand.com/" hreflang="th-th" />
                <link rel="alternate" href="https://www.firstpride.sg/" hreflang="en-sg" />
            </Helmet>
            <Hero />
            <ProductsHome />
            <AboutUsHome />
            <AboutUsHomeMobile />
            <WhereToBuyUs heading={true} color="orange" />
            <Faqs />
        </Layout>
    );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "hero", "navbar", "home", "footer", "aboutUsHome", "products", "faq", "whereToBuy"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;