import React from "react";
import { Carousel } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import GreenButton from "../Button/GreenButton";
import OrangeYellowButton from "../Button/OrangeYellowButton";
import img from "../../img/hero/hero1Tab.png";
import "./Hero.scss";
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";

const HeroTab = (props) => {
  const { language } = useI18next();
  const { t } = useTranslation();
  
  return (
    <div className={`heroWrapperTab `}>
      <Carousel className="carouselWrapperMobile" interval={3000}>
        <Carousel.Item className="carouselItem">
          {
          // props.props.i18n.
          language !== "th" ? (
            <StaticImage
              src="../../img/hero/hero1Tab.png"
              alt="hero1"
              className="w-100 d-block hero-img"
              placeholder="blurred"
            />
          ) : (
            <StaticImage
              src="../../img/hero/hero1TabThai.png"
              alt="FIRST PRIDE ที่สุดของแพลนต์เบส อร่อยถูกใจจนลืมไปเลยว่าทำมาจากพืช"
              className="w-100 d-block hero-img"
              placeholder="blurred"
            />
          )}
          <Carousel.Caption className="heroContent">
            <h2>{t("hero.title1","Plant-Based Goodness")}</h2>
            <h3>
              <span className="boldHero">{t("hero.boldsubtitle1","So Satisfying,")}</span>{" "}
              {t("hero.subtitle1","You won't believe it's made from plants.")}
            </h3>
            <div className="heroButton">
              <GreenButton
                msg={t("hero.button","Explore More")}
                color="green"
                to="/aboutus"
                yellow="#FFE055"
              />
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carouselItem">
          <StaticImage
            src="../../img/hero/hero2Tab.png"
            alt="FIRST PRIDE ประสบการณ์ความอร่อยของอาหารแพลนต์เบส ด้วยหลากหลายเมนูที่ทำให้อร่อยได้อย่างง่าย ๆ"
            className="w-100 d-block hero-img"
            placeholder="blurred"
          />
          <Carousel.Caption className="heroContent">
            <h2>{t("hero.title2","Plant-Based Goodness")}</h2>
            <h3>
              <span className="boldHero">{t("hero.boldsubtitle2","So Satisfying,")}</span>{" "}
              {t("hero.subtitle2","You won't believe it's made from plants.")}
            </h3>
            <div className="heroButton">
              <GreenButton
                msg={t("hero.button","Explore More")}
                color="green"
                to="/products#snacks"
                yellow="#FFE055"
              />
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carouselItem">
          {
          // props.props.i18n.
          language !== "th" ? (
            <StaticImage
              src="../../img/hero/hero4Tab.png"
              alt="FIRST PRIDE Thai Taste Series"
              className="w-100 d-block hero-img"
              placeholder="blurred"
            />
          ) : (
            <StaticImage
              src="../../img/hero/hero4TabThai.png"
              alt="FIRST PRIDE Thai Taste Series แพลนต์เบสอร่อยแซ่บ สไตล์ไทยแท้"
              className="w-100 d-block hero-img"
              placeholder="blurred"
            />
          )}
          <Carousel.Caption className="heroContent">
            <div className="heroButton">
              <OrangeYellowButton
                msg={t("hero.button","Explore More")}
                color="green"
                to="/products#thai"
                yellow="#FFE055"
              />
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carouselItem">
            <StaticImage
              src="../../img/hero4.png"
              alt="FIRST PRIDE J Series อร่อยแท้ ไม่แพ้เนื้อ"
              className="w-100 d-block hero-img"
              placeholder="blurred"
            />
            <Carousel.Caption className="heroContent">
              <div className="heroButton">
                <OrangeYellowButton
                  msg={t("hero.button","Explore More")}
                  color="green"
                  to="/products#snacks"
                  yellow="#FFE055"
                />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
      </Carousel>
      <div className="cornerGraphic">
        <StaticImage
          src="../../img/banner_pattern.png"
          alt="Patter"
          className="orangeGraphic"
        />
      </div>
    </div>
  );
};

export default HeroTab;