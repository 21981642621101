import styled from "styled-components";
import React from "react";
import { IoArrowForward, IoArrowBack } from "react-icons/io5";
import { BsCart } from "react-icons/bs";
import { OYShopButton, OYIconStyle, MsgWrapper } from "./styles";

const ForwardIcon = styled(IoArrowForward)``;
const BackwardIcon = styled(IoArrowBack)``;
const CartIcon = styled(BsCart)``;

const OrangeYellowButton = (props) => {
    return (
        <OYShopButton to={props.to} color={props.color} target={props.target}>
            <OYIconStyle>{props.icon === "cart" ? <CartIcon /> : props.icon === "backward" ? <BackwardIcon /> : <ForwardIcon />}</OYIconStyle>
            <MsgWrapper>{props.msg}</MsgWrapper>
        </OYShopButton>
    );
};
export default OrangeYellowButton;
