import React from "react";
import mobile1 from "../../img/aboutus/aboutusHomeMobile3.webp";
import mobile2 from "../../img/mobilebanner/aboutusHomeMobile2.webp";
import "./AboutUsHome.scss";
import OrangeButton from "../Button/OrangeButton";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const AboutUsHomeMobile = () => {
    const { t } = useTranslation();

    return (
        <div>
            <div>
                <br />
                <br />
            </div>
            <div className="aboutusHomeMobile">
                <div className="aboutusHomeMobileImg1">
                    <h2>{t("aboutUsHome.title1")}</h2>
                    <img loading="lazy" src={mobile1} alt="our brand" />
                </div>
                <div className="aboutusHomeMobileContent">
                    <div className="aboutusHomeMobileContentGrid">
                        <h3 className="brandHeaderMobile">{t("aboutUsHome.ourBrand.title1")}</h3>
                        <h2>
                            {t("aboutUsHome.ourBrand.subtitle1")}
                            <span className="together">{t("aboutUsHome.ourBrand.subtitle2")}</span>
                        </h2>
                        <h3 className="experienceHeaderMobile">{t("aboutUsHome.ourBrand.subsubtitle1")}</h3>
                        <p className="p-regular">{t("aboutUsHome.ourBrand.content1")}</p>
                        <OrangeButton msg={t("aboutUsHome.ourBrand.button1")} to="/aboutus" color="orange" />
                    </div>
                    <div className="aboutusHomeMobileImg2">
                        <img loading="lazy" src={mobile2} alt="our brand" />
                        <div className="aboutusHomeMobileContent2">
                            <div></div>
                            <div style={{ whiteSpace: "pre-wrap" }}>
                                <h3 className="innovationHeaderMobile">{t("aboutUsHome.ourInnovation.title1")}</h3>
                                <h2>{t("aboutUsHome.ourInnovation.subtitle1")}</h2>
                                <p className="p-regular">{t("aboutUsHome.ourInnovation.subsubtitle1")}</p>
                                <div className="btnWrapper1">
                                    <OrangeButton msg={t("aboutUsHome.ourInnovation.button1")} to="/aboutus#intraplant" color="orange" yellow="#fff" />
                                </div>
                                <div className="andSymbolMobile">&</div>
                                <h2>{t("aboutUsHome.ourInnovation.subtitle2")}</h2>
                                <p className="p-regular">{t("aboutUsHome.ourInnovation.subsubtitle2")}</p>
                                <div className="btnWrapper1">
                                    <OrangeButton msg={t("aboutUsHome.ourInnovation.button2")} to="/aboutus#threestep" color="orange" yellow="#fff" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="curveBg"></div>
            </div>
        </div>
    );
};

export default AboutUsHomeMobile;