import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ThaiSeries from "./ThaiSeries";
import SnackSeries from "./SnackSeries";
import JSeries from "./JSeries";
import "react-tabs/style/react-tabs.css";
import "./ProductsHome.scss";
import OrangeButton from "../Button/OrangeButton";
import GreenButton from "../Button/GreenButton";
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

const ProductsHome = (props) => {
    const { localizationSettings } = props;
    const { t } = useTranslation();

    return (
        <div className="productsHomeComponent">
            <div className="productsHomeWrapper">
                <h2>{t("productsHome.title1")}</h2>
                {localizationSettings?.language !== "Thai" ? (
                    <>
                        {/* <h5 style={{ marginBottom: "0" }}></h5> */}
                        <h5 style={{ marginBottom: "0" }} dangerouslySetInnerHTML={{ __html: t("productsHome.subtitle1","Think plant-based is boring?Think&nbsp;again.<br/>First pride offers a delicious range of <b>plant-based protein</b>  that is easy to enjoy.") }}>
                            {}
                        </h5>
                    </>
                ) : (
                    <>
                        <h5 style={{ marginBottom: "0" }} dangerouslySetInnerHTML={{ __html: t("productsHome.subtitle1","Think plant-based is boring?Think&nbsp;again.<br/>First pride offers a delicious range of <b>plant-based protein</b>  that is easy to enjoy.") }}>
                            {}
                        </h5>
                    </>
                )}
                <div className="productsHomeTabs">
                    <Tabs>
                        <TabList>
                            <Tab selectedTabClassName="react-tabs__tab--selected">
                                <StaticImage src="../img/snackseries.webp" alt="FIRST PRIDE Snack Series อร่อยง่าย ไม่รู้สึกผิด" className="tabImage" />
                            </Tab>
                            <Tab selectedTabClassName="react-tabs__tab--selected">
                                <StaticImage src="../img/thaiseries.webp" alt="FIRST PRIDE Thai Taste Series อร่อยแซ่บ แบบไทยแท้" className="tabImage" />
                            </Tab>
                            <Tab selectedTabClassName="react-tabs__tab--selected">
                                <StaticImage src="../img/Allium-free-series.png" alt="FIRST PRIDE J Series อร่อยแท้ ไม่แพ้เนื้อ" className="tabImage" />
                            </Tab>
                        </TabList>
                        <TabPanel>
                            <h2>
                                <SnackSeries />
                            </h2>
                        </TabPanel>
                        <TabPanel>
                            <h2>
                                <ThaiSeries />
                            </h2>
                        </TabPanel>
                        <TabPanel>
                            <h2>
                                <JSeries />
                            </h2>
                        </TabPanel>
                    </Tabs>
                </div>
                <div className="heroButton">
                    <GreenButton msg={t("productsHome.button1")} to="/products" />
                </div>
            </div>
        </div>
    );
};

export default (ProductsHome);
