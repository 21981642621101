import React from "react";
import "./AboutUsHome.scss";
import banner1 from "../../img/aboutusHome1.webp";
import banner2 from "../../img/aboutusHome3.webp";
import mobilebanner2 from "../../img/mobilebanner/aboutusHomeMobile2.webp";
import OrangeButton from "../Button/OrangeButton";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const AboutUsHome = () => {
    const { t } = useTranslation();

    return (
        <div className="aboutusHome">
            <img loading="lazy" src={banner1} alt="Banner 1" className="bannerImg" />
            <div className="aboutusHomeContent">
                <h2 className="aboutusHomeHeader">{t("aboutUsHome.title1")}</h2>
                <div className="aboutusHomeContentWrapper">
                    <div className="aboutusHomeContentGrid">
                        <h3 className="brandHeader">{t("aboutUsHome.ourBrand.title1")}</h3>
                        <h2>
                            {t("aboutUsHome.ourBrand.subtitle1")}
                            <span className="together">{t("aboutUsHome.ourBrand.subtitle2")}</span>
                        </h2>
                        <h3 className="experienceHeader p-regular">
                            {t("aboutUsHome.ourBrand.subsubtitle1")}
                            <b>{t("aboutUsHome.ourBrand.boldsubtitle")}</b>
                        </h3>
                        <p className="p-regular">{t("aboutUsHome.ourBrand.content1")}</p>
                        <OrangeButton msg={t("aboutUsHome.ourBrand.button1")} to="/aboutus" color="orange" yellow="#fff" />
                        <div className="bgdarkgreen"></div>
                    </div>
                </div>
                <div className="aboutusHomeContentWrapper2">
                    <img loading="lazy" src={banner2} alt="Banner 2" className="bannerImg2" />
                    <img loading="lazy" src={mobilebanner2} alt="Banner 2" className="bannerImgMobile" />
                    <div className="aboutusHomeContent2">
                        <div></div>
                        <div style={{ whiteSpace: "pre-wrap" }}>
                            <h3 className="innovationHeader">{t("aboutUsHome.ourInnovation.title1")}</h3>
                            <h2>{t("aboutUsHome.ourInnovation.subtitle1")}</h2>
                            <p className="p-regular">{t("aboutUsHome.ourInnovation.subsubtitle1")}</p>
                            <div className="btnWrapper1">
                                <OrangeButton msg={t("aboutUsHome.ourInnovation.button1")} to="/aboutus#intraplant" color="orange" yellow="#fff" />
                            </div>
                            <div className="andSymbol">&</div>
                            <h2>{t("aboutUsHome.ourInnovation.subtitle2")}</h2>
                            <p className="p-regular">{t("aboutUsHome.ourInnovation.subsubtitle2")}</p>
                            <div className="btnWrapper1">
                                <OrangeButton msg={t("aboutUsHome.ourInnovation.button2")} to="/aboutus#threestep" color="orange" yellow="#fff" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUsHome;